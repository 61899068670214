"use client"

import React, {useState, useEffect, useRef} from "react";
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import { jwtDecode } from "jwt-decode";
import {Bars3Icon, XMarkIcon,} from "@heroicons/react/24/outline";
import {usePathname, useRouter} from "next/navigation";
import Image from 'next/image'
import Link from 'next/link'

import { PmStore } from "@/state/store"
import { ExploreMenu } from "./ExploreMenu";
import {AuthorizationCheckRequired, eventBus} from "@/state";

export default function TopNavigationBar() {
  const [openNav, setOpenNav] = useState(false);
  const router = useRouter()
  const pathname = usePathname();
  let store = PmStore();


  const isAuthenticatedRef = useRef(PmStore.getState().isAuthenticated);
  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  useEffect(() => PmStore.subscribe(
      (state) => (isAuthenticatedRef.current = state.isAuthenticated)
  ), [])


  const token = useRef(PmStore.getState().token);
  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  useEffect(() => PmStore.subscribe(
      (state) => (token.current = state.token)
  ), [])



  const MINUTE_MS = 2000;

  useEffect(() => {
    const interval = setInterval(() => {
      // CheckAuth();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  function CheckAuth()
  {
    try {
      if (isAuthenticatedRef.current) {

        //let's look at the jwt now.
        const decoded = jwtDecode(token.current);
        let currentDate = new Date();
        if(decoded.exp * 1000 < currentDate.getTime())
        {

          store.resetToken();
          router.push("/");

        }

      }
      else {

        if(pathname.startsWith("/manage"))
        {
          store.resetToken();
          router.push("/");
        }

      }
    }
    catch (e) {

    }

  }



  eventBus.subscribe((event) => {
    switch (event.type) {
      case  AuthorizationCheckRequired :
        CheckAuth();
        break;
      default:
        break;
    }
  });





  function LogOut() {
    store.resetToken();
    router.push("/");
  }

  //
  return (
      <Navbar fullWidth={true} className={"sticky top-0    z-10 h-max rounded-none  lg:px-8 "}   shadow={true} >

        <div className={ ` w-full flex flex-grow items-center justify-between text-blue-gray-900 ${store.isAuthenticated ? "  ml-10 pr-5 ":" "}`} >


          <div className="flex items-center justify-between">
            <Image
                src="/logo.svg"
                width={30}
                height={30}
                alt="Picture of the author"
                className={`cursor-pointer ${store.isAuthenticated ? "hidden lg:block":""}`}
                onClick={() => router.push("/")}
            />
            <Typography
                as={Link}
                href="/"
                variant="h2"
                className="ml-4 md:text-3xl sm:text-xl hidden sm:block cursor-pointer  lg:ml-2"
                onClick={() => setOpenNav(false)}
            >
              <span style={{color: "#0055D4"}}>Rental</span>{" "}
              <span style={{color: "#FF6600"}}>Flow</span>
            </Typography>
          </div>



          <div className={" flex"}>
            <div className="hidden  lg:block mt-1 ">
              <ExploreMenu/>
            </div>

            <div className={"hidden  gap-2 lg:flex"}>
              {store.isAuthenticated ? (
                  <Button variant="gradient" size="sm" fullWidth onClick={() => LogOut()}>Sign Out</Button>
              ) : (
                  <Button variant="gradient" size="sm" fullWidth onClick={() => {setOpenNav(false);router.push("/login");}}>Sign In</Button>
              )}
            </div>

            <div>
              <div className={"float-end flex"}>
                <IconButton variant="text" color="black" className="lg:hidden" onClick={() => setOpenNav(!openNav)}>
                  {openNav ? (<XMarkIcon className="h-6 w-6" strokeWidth={2}/>) : (<Bars3Icon className="h-6 w-6" strokeWidth={2}/>)}
                </IconButton>
              </div>
            </div>
          </div>
        </div>
        <Collapse open={openNav}>
          <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden">
            {store.isAuthenticated ? (
                <Button variant="gradient" size="sm" fullWidth onClick={() => LogOut()}>Sign Out</Button>
            ) : (
                <Button variant="gradient" size="sm" fullWidth onClick={() => {setOpenNav(false);router.push("/login");}}>Sign In</Button>
            )}
          </div>
          <ExploreMenu/>
        </Collapse>
      </Navbar>
  );
}
